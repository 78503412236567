.footerContainer {
  background-color: #12263c;
  color: white;
  height: 19.58vw;
  display: flex;
  flex-direction: column;
}
.footerContainer > div > h2 {
  font-size: 1vw;
  margin-left: 14vw;
  margin-top: 3vw;
}
.footerContainer > div > p {
  font-size: 0.73vw;
  width: 30vw;
  line-height: 1.8vw;
  margin-left: 14vw;
}

.footerContainer > span {
  font-size: 0.73vw;
  align-self: center;
  margin-top: 3.88vw;
}
.footerImageContainer {
  display: none;
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    height: 29.77vw;
    flex-direction: unset;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  .footerImageContainer {
    height: 18.14vw;
    width: 16.98vw;
    background-color: #faf6ec;
    border-radius: 50%;
    display: unset;
    margin-left: 9.77vw;
  }
  .footerImage {
    height: 18.14vw;
    width: 16.98vw;
    border-radius: 50%;
  }
  .footerContainer > div > h2 {
    font-size: 1.86vw;
    letter-spacing: 0.22vw;
    margin-top: -5vw;
    margin-left: 22vw;
  }
  .footerContainer > div > p {
    font-size: 1.4vw;
    margin-left: 5vw;
    letter-spacing: 0.13vw;
    width: 58vw;
    text-align: right;
    line-height: 3vw;
    text-indent: 3vw;
  }
  .footerContainer > span {
    font-size: 1.05vw;
    margin-bottom: -15vw;
    margin-left: -50vw;
  }
}
