.podcast__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5vw;
  margin-top: 4vw;
  margin-bottom: 5vw;
}
.podcast__container > span {
  width: 60vw;
}
