:root {
  --animation-timing: 200ms ease-in-out;
}
.hamburgerMenu {
  display: none;
}
.dropDown {
  display: none;
}
.headerContainer {
  position: sticky;
  top: 0%;
  z-index: 200;
  background-color: #faf6ec;
}
.header__name {
  background-color: #faf6ec;
  height: 8vw;
  padding-left: 9.8vw;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.nameTopLeft {
  font-size: 1.46vw;
  font-weight: 400;
  letter-spacing: 0.22vw;
}
.logo {
  width: 11.69vw;
  height: 14vw;
  transform: rotate(80deg);
  background-image: url("https://utfs.io/f/0fe5cb3e-6de2-40fd-a8de-c645bc4aa9ec-svcfsh.webp");
  background-size: 11.69vw;
  background-position: 10% 30%;
  background-repeat: no-repeat;
  /* background-color: red; */
}

.header__name > a {
  text-decoration: none;
  color: #5f5c5c;
}
.cart {
  width: 1.7vw;
  margin-left: 2vw;
}

.headerRight {
  display: flex;
  column-gap: 2vw;
  margin-left: 21.4vw;
  text-transform: initial;
}
.headerRight > a {
  text-decoration: none;
  color: #12263c;
  font-size: 1.15vw;
  font-weight: bold;
  letter-spacing: 0.08vw;
}
.signUpHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 9.38vw;
  height: 2.34vw;
  font-size: 1.15vw;
  font-weight: 700;
  letter-spacing: 0.11vw;
  /* padding: 1vw 2vw; */
  letter-spacing: 0.2vw;
  color: #faf6ec;
  background-color: #12263c;
  border: none;
  border-radius: 0.78vw;
  margin-left: 3vw;
}

@media only screen and (max-width: 800px) {
  .dropDown {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 23.73vw;
    font-size: 4.19vw;
    letter-spacing: 0.31vw;
    font-weight: 600;
    right: 4.65vw;
    color: #ffffff;
    background-color: #dc2612;
    width: 36.28vw;
    height: 48.6vw;
    border: 0.23vw #faf6ec solid;
    border-radius: 1.16vw;
    z-index: 4;
    /* opacity: 0; */
  }
  .hamburgerMenu.hamburgerMenu:has(input:checked) + .dropDown {
    display: flex;
    /* opacity: 100%; */
    /* transition: opacity var(--animation-timing); */
  }

  .dropDown > nav {
    display: flex;
    flex-direction: column;
    row-gap: 10.5vw;
  }
  .dropDown > nav > a {
    text-decoration: none;
    color: white;
  }
  .hamburgerMenu::before,
  .hamburgerMenu::after,
  .hamburgerMenu input {
    content: "";
    width: 6.51vw;
    height: 0.5vw;
    background-color: #5f5c5c;
    border-radius: 2vw;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing),
      rotate var(--animation-timing), translate var(--animation-timing);
  }
  .hamburgerMenu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }
  .hamburgerMenu {
    display: flex;
    row-gap: 2.79vw;
    flex-direction: column;
    position: absolute;
    top: 16.5vw;
    right: 6.28vw;
    z-index: 4;
    cursor: pointer;
  }
  .hamburgerMenu:has(input:checked)::before {
    rotate: 45deg;
    width: 10.012vw;
    translate: 0, -5vw;
  }
  .hamburgerMenu:has(input:checked)::after {
    rotate: -45deg;
    width: 10.012vw;
    translate: 0, 5vw;
  }
  .hamburgerMenu input:checked {
    opacity: 0;
    width: 0;
  }

  .headerRight,
  .cart {
    display: none;
  }

  .header__name {
    height: 37vw;
    flex-direction: column;
    margin-right: 7vw;
  }
  .logo {
    margin-top: 2vw;
    width: 31vw;
    height: 30vw;
    background-size: 31vw;
    background-position: 10% 30%;
  }
  .nameTopLeft {
    font-size: 4.18vw;
    font-weight: 400;
    letter-spacing: 0.63vw;
  }

  .header__name > a {
    position: absolute;
    margin-top: 19.3vw;
  }
  .signUpHeader {
    /* margin-top: 29vw;
    margin-right: 9vw;
    cursor: pointer;
    width: 52.33vw;
    height: 13.87vw;
    font-size: 7.21vw;
    font-weight: 700;
    letter-spacing: 0.87vw;
    border-radius: 2.33vw; */
    display: none;
  }

  /* stopped here */
  /* 
  .headlineLeft {
    background-color: #ededed;
    width: 100vw;
    height: 15.34vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vw;
  }
  .headlineLeft > p {
    color: transparent;
  }
  .headlineLeft > h2 > br {
    display: none;
  }
  .headlineLeft > h2 {
    width: 74vw;
    text-align: center;
    font-size: 3.2vw;
    line-height: 5vw;
    margin-top: -2.5vw;
  }
  .yellowBoom {
    width: 35vw;
    margin-top: -9.7vw;
    margin-left: -12.2vw;
    z-index: 1;
  }
  .moreInfo {
    display: none;
  }

  .choices {
    display: none;
  }
  .redBoom {
    width: 60vw;
    z-index: 1;
    margin-top: 47vw;
    margin-right: 97vw;
    transform: rotate(290deg);
  }
  .redBoom2 {
    width: 30vw;
    z-index: 1;
    margin-top: 62vw;
    margin-left: 92vw;
    transform: rotate(65deg);
  }
  .startJourney {
    margin: unset;
    margin-top: -2.7vw;
    margin-bottom: 4vw;
    margin-right: 8vw;
    font-size: 2.8vw;
    letter-spacing: 0.21vw;
    font-weight: 500;
    font-style: italic;
    font-family: "Cabin";
  }
  .yellowUnderline {
    background-color: #ffbe01;
    width: 54.5vw;
    height: 0.5vw;
    position: absolute;
    margin-top: 80vw;
  } */
}
